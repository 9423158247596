import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import {
  Typography,
  Box,
  InputLabel,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useLocation } from "react-router-dom";

// Define types for the Wetsuit and User Measurements
interface Brand {
  brand_id: number;
  brand_name: string;
  brand_wiki_phrase: string;
}

type BrandsFilterProps = {
  onClick: (page: string) => void;
};
const BrandsFilter: React.FC<BrandsFilterProps> = ({ onClick }) => {
  const location = useLocation();

  const [brandsData, setBrandsData] = useState<Brand[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Create a new URLSearchParams object using the query string from the location
  const queryParams = new URLSearchParams(location.search);

  // Use the get() method to access specific query string parameters, provide defaults if missing
  const brand = queryParams.get("brand") || "All";
  const country = queryParams.get("country") || "All";
  const gender = queryParams.get("gender") || "All";
  const isMetric = queryParams.get("ismetric") || "true";

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const isDevelopment = process.env.NODE_ENV === "development";
      let API_URL: string | undefined = isDevelopment
        ? process.env.REACT_APP_BRANDSLIST_API_DEV_URL
        : process.env.REACT_APP_BRANDSLIST_API_PROD_URL;

      if (!API_URL) {
        API_URL = "https://prod-wetsuitsizer.azurewebsites.net/suggestions";
      }
      try {
        const APIKEY = process.env.REACT_APP_APIKEY;
        const response = await axios.get(API_URL, {
          headers: {
            "x-api-key": APIKEY,
          },
        });

        setBrandsData(response.data);

        setLoading(false);
      } catch (err) {
        setError(`Failed to fetch data: ${err}`);
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Pass an empty array to ensure this effect runs only once on component mount

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", mt: 10 }}>
        <CircularProgress size={80} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <>
      {brandsData.length === 0 ? (
        <Typography variant="h4" align="center" sx={{ mt: 4 }}>
          No brands found. Please adjust your search criteria.
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 2,
            mt: 2,
            mb: 1,
          }}
        >
          <InputLabel variant="standard" id="brand-select-label">
            Brand:
          </InputLabel>
          <Select
            labelId="brand-select-label"
            id="brand-select"
            variant="standard"
            value={brand}
            label="Brand:"
            onChange={(e) => {
              const selectedBrand = e.target.value;
              onClick(
                `viewbrands?brand=${selectedBrand}&country=${country}&gender=${gender}&isMetric=${isMetric}`
              );
            }}
          >
            <MenuItem value="All">All</MenuItem>
            {brandsData.map((brandItem) => (
              <MenuItem
                key={brandItem.brand_id}
                value={brandItem.brand_name}
                selected={brandItem.brand_name === brand}
              >
                {brandItem.brand_name}
              </MenuItem>
            ))}
          </Select>
          <InputLabel variant="standard" id="country-select-label">
            Country:
          </InputLabel>
          <Select
            labelId="country-select-label"
            id="country-select"
            variant="standard"
            value={country}
            label="Country:"
            onChange={(e) => {
              const selectedCountry = e.target.value;
              onClick(
                `viewbrands?brand=${brand}&country=${selectedCountry}&gender=${gender}&isMetric=${isMetric}`
              );
            }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem key={"US"} value={"United States"}>
              United States
            </MenuItem>
            <MenuItem key={"UK"} value={"United Kingdom"}>
              United Kingdom
            </MenuItem>
            <MenuItem key={"AU"} value={"Australia"}>
              Australia
            </MenuItem>
          </Select>
          <Box
            sx={{
              display: { xs: "block", sm: "inline-block" },
              width: { xs: "100%", sm: "auto" },
              mt: { xs: 0, sm: 0 },
            }}
          />
          <InputLabel variant="standard" id="gender-select-label">
            Gender:
          </InputLabel>
          <Select
            labelId="gender-select-label"
            id="gender-select"
            variant="standard"
            value={gender}
            label="Gender:"
            onChange={(e) => {
              const selectedGender = e.target.value;
              onClick(
                `viewbrands?brand=${brand}&country=${country}&gender=${selectedGender}&isMetric=${isMetric}`
              );
            }}
          >
            <MenuItem key={"All"} value={"All"}>
              All
            </MenuItem>
            <MenuItem key={"Men"} value={"Men"}>
              Men
            </MenuItem>
            <MenuItem key={"Women"} value={"Women"}>
              Women
            </MenuItem>
          </Select>
          <InputLabel variant="standard" id="unit-select-label">
            Units:
          </InputLabel>
          <ToggleButtonGroup
            color="primary"
            value={
              queryParams.get("isMetric") === "true" ? "Metric" : "Imperial"
            }
            exclusive
            onChange={(e, newUnit) => {
              if (newUnit !== null) {
                const isMetric = newUnit === "Metric";
                onClick(
                  `viewbrands?brand=${brand}&country=${country}&gender=${gender}&isMetric=${isMetric}`
                );
              }
            }}
            sx={{
              "& .MuiToggleButtonGroup-grouped": {
                border: "1px solid rgba(0, 0, 0, .5)", // Add a border to make buttons distinct
                "&.Mui-selected": {
                  backgroundColor: "#ccc", // Semi-transparent yellow background when selected
                  color: "#000", // White text when selected
                },
                "&:not(.Mui-selected)": {
                  backgroundColor: "rgba(255, 255, 255, 1.0)", // Semi-transparent light grey when not selected
                  color: "#000", // Black text when not selected
                },
              },
            }}
            aria-label="unit"
          >
            <ToggleButton
              value="Metric"
              aria-label="metric"
              sx={{
                padding: 1,
                margin: 0,
                minWidth: 0,
              }}
            >
              <Typography variant="subtitle2">Metric</Typography>
            </ToggleButton>
            <ToggleButton
              value="Imperial"
              aria-label="imperial"
              sx={{
                padding: 1,
                margin: 0,
                minWidth: 0,
              }}
            >
              <Typography variant="subtitle2">Imperial</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
    </>
  );
};

export default BrandsFilter;
