import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid2,
  CircularProgress,
  Rating,
  Divider,
  Box,
  Avatar,
  Button,
} from "@mui/material";

import axios from "axios";
import MeasurementDisplay from "./MeasurementCard";
import CardHeader from "@mui/material/CardHeader";
import ThisIsMyFitButton from "./ThisIsMyFitButton";
import ShowAllWetsuits from "./ShowAllWetsuits";
import StarIcon from "@mui/icons-material/Star";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";

// Conversion functions
const cmToInches = (cm: number): number => Math.round(cm * 0.393701);
const inchesToCm = (inches: number): number => Math.round(inches * 2.54);
const kgToLbs = (kg: number): number => Math.round(kg * 2.20462);
const lbsToKg = (lbs: number): number => Math.round(lbs * 0.453592);

const sanitizeBrandName = (brandName: string): string => {
  return brandName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
};

const getLogoSrc = (brandName: string): string => {
  const sanitizedBrand = sanitizeBrandName(brandName);

  try {
    return require(`../logos/${sanitizedBrand}.png`);
  } catch (error) {
    return require("../brand/logo_small.png");
  }
};

// Define types for the Wetsuit and User Measurements
interface Wetsuit {
  product_id: number;
  brandID: number;
  genderID: number;
  countryID: number;
  brand: string;
  type: string;
  genderName: string;
  heightMin: number;
  heightMax: number;
  weightMin: number;
  weightMax: number;
  chestMin: number;
  chestMax: number;
  waistMin: number;
  waistMax: number;
  size: string;
  countryName: string;
  countryCode: string;
  score: number;
  affiliateLinks: string[];
  affiliateProgramNames: string[];
  affiliateProgramCodes: string[];
  affiliateCountries: string[];
}

interface UserMeasurements {
  height: number;
  chest: number;
  waist: number;
  weight: number;
  gender: string;
  country: string;
  isMetric: boolean;
  isSectionCollapsed?: boolean;
}

interface WetsuitDisplayProps {
  formData: UserMeasurements;
}

const WetsuitCard: React.FC<{
  wetsuit: Wetsuit;
  isMetric: boolean;
  height: number;
  weight: number;
  chest: number;
  waist: number;
  country: string;
  gender: string;
  index: number;
}> = ({
  wetsuit,
  isMetric,
  height,
  weight,
  chest,
  waist,
  country,
  gender,
  index,
}) => {
  const heightMin = isMetric
    ? wetsuit.heightMin
    : cmToInches(wetsuit.heightMin);
  const heightMax = isMetric
    ? wetsuit.heightMax
    : cmToInches(wetsuit.heightMax);
  const weightMin = isMetric ? wetsuit.weightMin : kgToLbs(wetsuit.weightMin);
  const weightMax = isMetric ? wetsuit.weightMax : kgToLbs(wetsuit.weightMax);
  const chestMin = isMetric ? wetsuit.chestMin : cmToInches(wetsuit.chestMin);
  const chestMax = isMetric ? wetsuit.chestMax : cmToInches(wetsuit.chestMax);
  const waistMin = isMetric ? wetsuit.waistMin : cmToInches(wetsuit.waistMin);
  const waistMax = isMetric ? wetsuit.waistMax : cmToInches(wetsuit.waistMax);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card
      raised={false}
      sx={{
        border: "1px solid black",
        boxShadow: "none",
        height: "auto",
        padding: "10px",
        borderRadius: "16px",
      }}
    >
      <CardHeader
        sx={{
          display: "flex",
          backgroundColor: index === 0 ? "#fff" : "#fff",
          "& .MuiCardHeader-content": {
            overflow: "hidden",
          },
          paddingTop: "5px ",
          paddingBottom: "0px",
        }}
        title={wetsuit.brand}
        titleTypographyProps={{ noWrap: true, variant: "h6" }}
        // subheader={`Recommended Size: ${wetsuit.sizeName}`}
        subheaderTypographyProps={{
          noWrap: true,
          variant: "subtitle2",
          fontWeight: "bold",
        }}
        avatar={
          <Avatar
            sx={{ width: 50, height: 50, color: "white", borderRadius: 0 }}
            alt="Brand Logo"
            src={getLogoSrc(wetsuit.brand)}
          />
        }
        action={
          <Avatar sx={{ bgcolor: "white", border: "1px solid black" }}>
            <Typography variant="body2" color="primary">
              <strong>{wetsuit.score}%</strong>
            </Typography>
          </Avatar>
        }
      />
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" fontWeight={"bold"}>
            Size: {wetsuit.size}
          </Typography>
          <Typography
            variant="h6"
            color="text.secondary"
            sx={{ mr: 2 }}
          ></Typography>
          <Box
            sx={{
              width: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2">Fit Rating:</Typography>
            <Rating
              name="read-only"
              value={wetsuit.score / 20}
              readOnly
              icon={<StarIcon fontSize="inherit" />}
              emptyIcon={<StarOutlinedIcon fontSize="inherit" />}
            />
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />

        <div style={{ paddingLeft: "0px" }}>
          <MeasurementDisplay
            label="Height"
            min={heightMin}
            max={heightMax}
            currentValue={height}
            unit={isMetric ? "cm" : "inches"}
          />

          <MeasurementDisplay
            label="Weight"
            min={weightMin}
            max={weightMax}
            currentValue={weight}
            unit={isMetric ? "kg" : "lbs"}
          />

          <MeasurementDisplay
            label="Chest"
            min={chestMin}
            max={chestMax}
            currentValue={chest}
            unit={isMetric ? "cm" : "inches"}
          />

          <MeasurementDisplay
            label="Waist"
            min={waistMin}
            max={waistMax}
            currentValue={waist}
            unit={isMetric ? "cm" : "inches"}
          />
        </div>

        {/* <Divider sx={{ my: 2 }} />
        {wetsuit.affiliateLinks && wetsuit.affiliateLinks.length > 0 ? (
          wetsuit.affiliateLinks.map((link, index) => {
            const programName =
              wetsuit.affiliateProgramNames[index] || "Buy Now";
            const programLink =
              link + `?tag=${wetsuit.affiliateProgramCodes[index]}`;
            return (
              <Box sx={{ display: "flex", alignItems: "center" }} key={index}>
                <Typography variant="body2">
                  <a
                    href={programLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Buy from {programName}
                  </a>
                </Typography>
              </Box>
            );
          })
        ) : (
          <Typography variant="body2">
            No purchase options available.
          </Typography>
        )} */}
        <Box>
          {/* <Divider sx={{ my: 2 }} />
          <Typography variant="body2" gutterBottom>
            Let us know if you have this wetsuit in this size and if it fits or
            not. We do not store any personal information.
          </Typography> */}
          {/* <ThisIsMyFitButton
            productId={wetsuit.product_id.toString()}
            userFitData={{
              height,
              chest,
              waist,
              weight,
              gender,
              country,
            }}
          /> */}
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpen}
              sx={{ textTransform: "none", borderRadius: "5px" }}
            >
              <Typography variant="subtitle2">
                Show All {wetsuit.brand} {wetsuit.countryCode}{" "}
                {wetsuit.genderName}'s Sizes
              </Typography>
            </Button>
          </Box>
          <ShowAllWetsuits
            brand_id={wetsuit.brandID}
            gender_id={wetsuit.genderID}
            country_id={wetsuit.countryID}
            size_name={wetsuit.size}
            open={open}
            onClose={handleClose}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

const WetsuitDisplay: React.FC<WetsuitDisplayProps> = ({ formData }) => {
  const [wetsuitData, setWetsuitData] = useState<Wetsuit[]>([]);
  const [loading, setLoading] = useState(true);
  const [error] = useState<string | null>(null);

  const { isMetric } = formData;

  useEffect(() => {
    const fetchData = async () => {
      const metricHeight = isMetric
        ? formData.height
        : inchesToCm(formData.height);
      const metricChest = isMetric
        ? formData.chest
        : inchesToCm(formData.chest);
      const metricWaist = isMetric
        ? formData.waist
        : inchesToCm(formData.waist);
      const metricWeight = isMetric
        ? formData.weight
        : lbsToKg(formData.weight);

      setLoading(true);
      const isDevelopment = process.env.NODE_ENV === "development";
      let API_URL: string | undefined = isDevelopment
        ? process.env.REACT_APP_SUGGESTIONS_API_DEV_URL
        : process.env.REACT_APP_SUGGESTIONS_API_PROD_URL;

      if (!API_URL) {
        API_URL = "https://prod-wetsuitsizer.azurewebsites.net/suggestions";
      }

      const APIKEY = process.env.REACT_APP_APIKEY;
      const url = `${API_URL}?userHeight=${metricHeight}&userChest=${metricChest}&userWaist=${metricWaist}&userWeight=${metricWeight}&userGender=${formData.gender}&userCountry=${formData.country}`;

      const response = await axios.get(url, {
        headers: {
          "x-api-key": APIKEY,
        },
      });

      setWetsuitData(response.data);
      setLoading(false);
    };

    fetchData();
  }, [
    formData.country,
    formData.gender,
    formData.height,
    formData.chest,
    formData.waist,
    formData.weight,
    isMetric,
  ]);

  if (loading) {
    return (
      <Container sx={{ textAlign: "center", mt: 10 }}>
        <CircularProgress size={80} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <>
      {wetsuitData.length === 0 ? (
        <Typography variant="h4" align="center" sx={{ mt: 4 }}>
          No wetsuits found. Please adjust your search criteria.
        </Typography>
      ) : (
        <Grid2
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 3, sm: 12, md: 12, lg: 12, xl: 12 }}
          direction={{ xs: "column", sm: "row" }}
        >
          {wetsuitData.map((wetsuit, index) => (
            <Grid2
              size={{ xs: 3, sm: 6, md: 4, lg: 3, xl: 3 }}
              key={`${wetsuit.product_id}-${wetsuit.size || index}`}
            >
              <WetsuitCard
                wetsuit={wetsuit}
                country={formData.country}
                gender={formData.gender}
                isMetric={isMetric}
                height={formData.height}
                weight={formData.weight}
                chest={formData.chest}
                waist={formData.waist}
                index={index}
              />
            </Grid2>
          ))}
        </Grid2>
      )}
    </>
  );
};

export default WetsuitDisplay;
