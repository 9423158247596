import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface Size {
  sizeID: number;
  size: string;
  productID: number;
  brandID: number;
  brandName: string;
  genderID: number;
  genderName: string;
  countryID: number;
  countryName: string;
  countryCode: string;
  heightMin: number;
  heightMax: number;
  weightMin: number;
  weightMax: number;
  chestMin: number;
  chestMax: number;
  waistMin: number;
  waistMax: number;
}

interface ShowAllWetsuitsProps {
  brand_id: number;
  gender_id: number;
  country_id: number;
  size_name: string;
  open: boolean;
  onClose: () => void;
}

const ShowAllWetsuits: React.FC<ShowAllWetsuitsProps> = ({
  brand_id,
  gender_id,
  country_id,
  size_name,
  open,
  onClose,
}) => {
  const [sizes, setSizes] = useState<Size[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSizes = async () => {
      setLoading(true);
      try {
        const isDevelopment = process.env.NODE_ENV === "development";
        const API_URL: string | undefined = isDevelopment
          ? process.env.REACT_APP_SIZES_API_DEV_URL
          : process.env.REACT_APP_SIZES_API_PROD_URL;

        const APIKEY = process.env.REACT_APP_APIKEY;

        const response = await axios.get(`${API_URL}`, {
          params: {
            brand_id,
            gender_id,
            country_id,
          },
          headers: {
            "x-api-key": APIKEY,
          },
        });

        setSizes(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data.");
        setLoading(false);
      }
    };

    if (open) {
      fetchSizes();
    }
  }, [brand_id, gender_id, country_id, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={window.innerWidth <= 600}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            {sizes.length > 0 && (
              <>
                <Typography variant="h6" align="center">
                  {sizes[0].brandName} {sizes[0].countryCode}{" "}
                  {sizes[0].genderName} Wetsuits
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Size Name</TableCell>
                        <TableCell>Height Min</TableCell>
                        <TableCell>Height Max</TableCell>
                        <TableCell>Weight Min</TableCell>
                        <TableCell>Weight Max</TableCell>
                        <TableCell>Chest Min</TableCell>
                        <TableCell>Chest Max</TableCell>
                        <TableCell>Waist Min</TableCell>
                        <TableCell>Waist Max</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sizes.map((size) => (
                        <TableRow
                          key={size.sizeID}
                          sx={{
                            backgroundColor:
                              size.size === size_name
                                ? "lightyellow"
                                : "inherit",
                          }}
                        >
                          <TableCell>
                            {size.size === size_name ? (
                              <b>{size.size}</b>
                            ) : (
                              size.size
                            )}
                          </TableCell>
                          <TableCell>
                            {size.size === size_name ? (
                              <b>{size.heightMin}</b>
                            ) : (
                              size.heightMin
                            )}
                          </TableCell>
                          <TableCell>
                            {size.size === size_name ? (
                              <b>{size.heightMax}</b>
                            ) : (
                              size.heightMax
                            )}
                          </TableCell>
                          <TableCell>
                            {size.size === size_name ? (
                              <b>{size.weightMin}</b>
                            ) : (
                              size.weightMin
                            )}
                          </TableCell>
                          <TableCell>
                            {size.size === size_name ? (
                              <b>{size.weightMax}</b>
                            ) : (
                              size.weightMax
                            )}
                          </TableCell>
                          <TableCell>
                            {size.size === size_name ? (
                              <b>{size.chestMin}</b>
                            ) : (
                              size.chestMin
                            )}
                          </TableCell>
                          <TableCell>
                            {size.size === size_name ? (
                              <b>{size.chestMax}</b>
                            ) : (
                              size.chestMax
                            )}
                          </TableCell>
                          <TableCell>
                            {size.size === size_name ? (
                              <b>{size.waistMin}</b>
                            ) : (
                              size.waistMin
                            )}
                          </TableCell>
                          <TableCell>
                            {size.size === size_name ? (
                              <b>{size.waistMax}</b>
                            ) : (
                              size.waistMax
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ShowAllWetsuits;

// Add this line to make the file a module
export {};
